.cart .details {
    margin-top: 4%;
}

.cart .list-group-item {
    display: flex !important;
}

.cart .details .items {
    border-right: 1px solid rgba(0, 0, 0, .2);
}

.cart .details .items .right {
    text-align: right;
}

.cart .details .items .no-margins {
    margin-bottom: 0 !important;
}

.cart .details .summary .order {
    max-width: 40%;
}

.cart .order {
    width: 100%;
}

.cart .details .list-group .btn-info {
    background-color: white !important;
    color: black !important;
    border-color: grey !important;
    font-size: 1.015rem;
}