body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* position: absolute; */
  width: 100%;
  color: #212529;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.products .list-group-item+.list-group-item {
  border-top-width: 1px;
}

.app-main {
  /* background-image: url("/media/img/background-main.jpg"); */
  background: #faf9f8;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  overflow: auto;
  background-attachment: fixed;
  position: absolute;

}

.white {
  color: #ffffff;
}

.red {
  color: red;
}

.logo-section, .search-bar , .menu-section {
  text-align: center;
  margin: 0 auto;
}

.logo {
  width: 55%;
}

.bar-input {
  width: 80%;
}

.navbar-nav {
  flex-direction: row !important;
}

/* LOGIN */

@media only screen and (max-width: 479px) {
  .login-wrapper {
    width: 100% !important;
    padding-left: 5%;
    padding-top: 5%;
  }
}

@media screen and (min-width: 480px) and (max-width: 1279px) {
  .login-wrapper {
    width: 60% !important;
    padding-left: 5%;
    padding-top: 5%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 2559px) {
  .login-wrapper {
    width: 30% !important;
    padding-left: 5%;
    padding-top: 5%;
  }
}


.login-wrapper .form-signin-heading {
  color: #ffffff;
}

.btn-primary {
  background-color: #1C9FDD !important;
  border-color: #1C9FDD !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  background-color: #475877 !important;
  border-color: #475877 !important;
}

.accordion {
  background: none !important;
  border: none !important;
}

/* PRODUCT LIST */
.society-name {
  margin-top: auto !important;
}

.navbar .navbar-header .navbar-brand {
  color: #ffffff;
}

.navbar {
  background-color: #ffffff;
}

/* .products-panel .product-img {
  width: 15%;
}

.products-panel .details {
  padding-left: 5%;
  width: 50%;
}

.products-panel .product-img img {
  width: 100%;
} */

@media screen and (min-width: 1280px) {
  .container {
    max-width: 80% !important;
  }
  
}

@media screen and (max-width: 1279px) {
  .container {
    max-width: 100% !important;
  }  
}



/* ADMIN */
.product-management {
  background-color: white
}

.modify-product .nav, .exchange, .business-increase {
  padding-top: 1%;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

/* HEADER */
#content-wrap {
  padding-bottom: 10.2rem;
}

/* INVOICES */
.react-datepicker-wrapper {
  width: auto;
}
