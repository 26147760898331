body
    color: #000

.searchApp
    h2
        text-align: center
        margin-top: 20px
    input.input
        width: 100%
        height: 50px
        margin: 20px 0px
        font-size: 17px
        padding-left: 20px
    .card
        display: flex
        flex-direction: column
        align-items: center
        p
            margin: 10px 0px
        img
            width: 400px
    .resultCard
        margin: 15px
        text-align: center
        padding: 20px
        box-shadow: 8px 7px 13px 0px #dadada
        border: 1px solid #e6e6e6
        background-color: #ffffff
    .result
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-around
    figure.image 
        padding-bottom: 15px

.bolder
    font-weight: 600