.products .title {
    color: #ffffff;
}

.products a {
    color: #000000;
}

.products .item {
    color: #000000;
    background-color: rgba(214, 246, 250, 0.712);
    transition: .2s;
}

.products .item:hover {
    color: #000;
    background-color: rgba(246, 246, 246, 0.655);
    transition: .2s;
}

.products a:hover {
    color: #000;
    text-decoration: none
}

.products .filters {
    margin-top: 4%;
}

.products .items .btn-primary {
    background-color: #4BAAC8 !important;
    border-color: #4BAAC8 !important;
}

.products a {
    color: #000000;
}

.products .item {
    color: #000000;
    background-color: rgba(214, 246, 250, 0.712);
    transition: .2s;
}

.products .item:hover {
    color: #000;
    background-color: rgba(246, 246, 246, 0.655);
    transition: .2s;
}

.products a:hover {
    color: #000;
    text-decoration: none
}

.products .filters {
    margin-top: 4%;
}

@media screen and (min-width: 1280px) {

    .products .items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .products .items .item .low-resolution {
        display: none !important;
    }

    .products .items .item .item-details {
        display: grid;
        grid-template-columns: 20% 40% 40%;
    }

    .products .items .product-img {
        width: 85%;
    }

    .products .items .product-img img {
        width: 100%;
    }

    .products .filters .input {
        display: grid;
        grid-template-columns: 20% 20%;
    }
}

@media screen and (max-width: 1279px) {

    .products .items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .products .items .item .high-resolution {
        display: none !important;
    }

    .products .items .item .item-details {
        display: grid;
        grid-template-columns: 58% 40%;
    }

    .products .items .product-img-details {
        border-right: 1px solid black;
    }

    .products .items .price {
        margin-left: 2%;
    }

    .products .items .product-img-details img {
        width: 70%;
    }

    .products .filters .input {
        display: grid;
        grid-template-columns: 35% 35%;
    }
}

.products .items .item {
    margin: 0.5em;
    position: relative;
}

.products .items .item button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.delivery-message {
    color: red;
}