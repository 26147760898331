.menu-page #content-wrap {
  padding-bottom: 1rem;
}

.menu-page .products {
  display: flex;
  height: 100vh;
}

.menu-page .products .items {
  width: 77%;
  overflow-y: auto;
  position: absolute;
  right: 0;
}

/* menu on desktop */
.menu-page .menus {
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  background-color: #fff;
  width: 20%;
  padding-left: 0 !important;
}

.menu-page .menus .menu-items {
  position: relative;
  /* border: 1px solid gray; */
  display: flex;
}

.menu-page .menus .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  width: 100%;
}

.menu-page .menus .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-page .menus button span {
  margin-left: 3px;
}

.menu-page .menus .menu-items > a,
.menu-page .menus .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-page .menus .menu-items a:hover,
.menu-page .menus .menu-items button:hover {
  background-color: #f2f2f2;
}

.menu-page .menus .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.menu-page .menus .dropdown-component {
  position: absolute;
  /* right: 0; */
  left: 100%;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding-left: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.menu-page .menus .dropdown-component.show {
  display: block;
}

.menu-page .menus .dropdown-component .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* menu on mobile */
@media screen and (max-width: 960px) {

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .menu-page {
    display: block;
  }

  .menu-page .products {
    display: block;
    height: 100vh;
  }

  .menu-page .products .items {
    width: 100%;
    overflow: hidden;
  }

  .menu-page .menu-page__menu-button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
  }
  .menu-page .menus {
    list-style: none;
    position: relative;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
    width: 100%;
    overflow-y: auto;
  }

  .menu-page .menus .menu-items {
    display: block;
  }

  .menu-page .menus .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    width: 100%;
  }

  .menu-page .menus .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .menu-page .menus .menu-items > a,
  .menu-page .menus .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .menu-page .menus .menu-items a:hover,
  .menu-page .menus .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .menu-page .menus .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .menu-page .menus .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .menu-page .menus .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .menu-page .menus .dropdown-component {
    left: 0;
  }

  .menu-page .menus .dropdown.show {
    display: block;
  }
}