.product {
    margin-top: 2%;
}

.product .details {
    border-right: 1px solid rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: row;
}

.product .product-img {
    width: 40%;
}

.product .product-img img {
    width: 100%;
}

.product .product-info {
    padding-left: 7%;
}

.product .stock {
    border-right: 1px solid rgba(0, 0, 0, .2);
}

