@charset "utf-8"

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')

// Set your brand colors
$purple: #8a4d76
$pink: #fa7c91
$brown: #757763
$beige-light: #d0d1cd
$beige-lighter: #eff0eb

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif
$grey-dark: $brown
$grey-light: $beige-light
$primary: $purple
$link: $pink
$widescreen-enabled: false
$fullhd-enabled: false

// Update some of Bulma's component variables
$body-background-color: #f5f5f5
$control-border-width: 2px
$input-border-color: transparent
$input-shadow: none

// Import only what you need from Bulma but I have imported all for now
@import '../node_modules/bulma/sass/utilities/_all.sass'
// @import '../node_modules/bulma/sass/base/_all.sass'
// @import '../node_modules/bulma/sass/elements/button.sass'
@import '../node_modules/bulma/sass/elements/container.sass'
// @import '../node_modules/bulma/sass/elements/form.sass'
@import '../node_modules/bulma/sass/elements/title.sass'
// @import '../node_modules/bulma/sass/components/navbar.sass'
// @import '../node_modules/bulma/sass/layout/hero.sass'
// @import '../node_modules/bulma/sass/layout/section.sass'
