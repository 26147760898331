.footer {
    position:absolute;
    bottom: 0;
    width: 100%;
    height: 10.2rem;
    padding-top: 3%;
    /* background:rgba(114, 25, 187, 0.855); */
}

.footer .company {
    list-style: none;
    padding: 0;
}

.footer .container {
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding-top: 1%;
}