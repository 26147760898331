.admin .orders-filters {
    display: flex;
    margin-top: 1%;
    margin-bottom: 1%;
}

.admin .orders-filters .apply-button {
    position: relative;
}

.admin .orders-filters .apply-button button {
    white-space: nowrap;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.orders-filters .orders {
    border-bottom: 2px solid #000;
    margin-bottom: 2%;
}

.orders-filters .orders:hover {
    background: #f8f8f8;
}

.orders-filters .orders .buttons {
    margin-bottom: 2%;
}
  