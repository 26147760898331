.my-account .my-account-menu ul {
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.my-account .my-account-menu .btn {
    color: #3c4043 !important;
}

.my-account .my-account-menu .btn:hover {
    background-color: #F6F9FE;
}

.my-account .my-account-menu .orders {
    border-right: 1px solid #dee2e6 !important;
}