.dropdown {
    position: relative;
    z-index: 100 !important;
}

.menu {
    position: absolute;

    list-style-type: none;
    margin: 5px 0;
    padding: 0;

    border: 1px solid grey;
    width: 100%;
}

.menu > li {
    margin: 0;
    background-color: white;
}

.menu > li:hover {
    background-color: lightgray;
}

.menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;

    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
}

.right-menu {
  display: flex;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.right-menu .btn {
  /* background-color: #17a2b8; */
  color: #3c4043 !important;
}

.right-menu .nav-item {
  display: flex;
  align-items: center;
}

.right-menu .border-right {
  border-right: 1px solid rgba(0, 0, 0, .2);
}

.right-menu .btn-partners {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.right-menu .btn-cart {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.right-menu .btn:hover {
  background-color: #F6F9FE;
}

.container .menu-section .right-menu {
  border-style: none;
  border-radius: 24px;
}

.account-menu {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 1em;
  font-weight: 500;
  height: 2.5em;
  justify-content: center;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.account-menu:hover {
  background: #F6F9FE;
}

.account-menu:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.account-menu:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.account-menu:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.account-menu:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.account-menu:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.account-menu:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

@media screen and (max-width: 1279px) {
  .container .menu-section {
    text-align: left !important;
    margin-left: 0 !important;
  }

  .search-bar {
    margin-bottom: 2% !important;
  }
}